import React, { useEffect, useState } from "react";
import heroBg from "./../assets/Herofinal.png";
import heroBgSmall from "./../assets/HerofinalSmall.png";
import { useTranslation } from "react-i18next";

const HeroSection = ({id}: {id:any}) => {
  const { t } = useTranslation();
  const [currentBg, setCurrentBg] = useState(
    window.innerWidth < 640 ? heroBgSmall : heroBg
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setCurrentBg(heroBgSmall);
      } else {
        setCurrentBg(heroBg);
      }
    };

    // Listen to resize events
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div id={id} className="flex flex-col h-svh">
      <div className="relative flex-1 w-full">
        <img
          src={currentBg}
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover object-center"
        />
        <div className="absolute inset-0 flex items-center">
          <div className="px-10 sm:px-40 w-full">
            <h1 className="text-[25px] sm:text-[30px] leading-[20px] sm:leading-[30px] font-bold text-red-600">
              {t('hero1')}
            </h1>
            <p className="text-[45px] sm:text-[70px] leading-[35px] sm:leading-[50px] font-bold text-yellow-500">
             {t('hero2')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
