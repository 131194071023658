import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AlbanianFlag, EnglandFlag } from "../assets/Icons";
import './styles.css';

const LanguageSwitcher = ({toggleMenu} : {toggleMenu: any}) => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng);
    setIsOpen(false); // Close the dropdown after selecting a language
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const icon = i18n.language === "en" ? <EnglandFlag /> : <AlbanianFlag />;

  return (
    <div>
      {!isOpen ? (
        <button onClick={toggleDropdown} className="flex items-center">
          {i18n.language.toLocaleUpperCase()}{" "}
          <span className="language-switcher-icon">{icon}</span>
        </button>
      ) : (
        <ul>
          <li
            onClick={() => {changeLanguage("en"); toggleMenu(false);}}
            className="hover:text-gray-300 flex items-center"
          >
            English{" "}
            <span className="language-switcher-icon">
              <EnglandFlag />
            </span>
          </li>
          <li
            onClick={() => {changeLanguage("sq"); toggleMenu(false);}}
            className="hover:text-gray-300 flex items-center"
          >
            Shqip{" "}
            <span className="language-switcher-icon">
              <AlbanianFlag />
            </span>
          </li>
        </ul>
      )}
    </div>
  );
};

export default LanguageSwitcher;
