import React from "react";
import { ServicesIcon1, ServicesIcon2, ServicesIcon3 } from "../assets/Icons";
import "../styles/AllServices.css";
import { ServicesCardFW } from "../components/ServicesCardFW";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

const ServicesScreen = () => {
  const { t } = useTranslation();
  const [prevScrollPos, setPrevScrollPos] = React.useState(window.pageYOffset);
  const [navbarHidden, setNavbarHidden] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingDown = prevScrollPos < currentScrollPos && currentScrollPos > 50;

      setNavbarHidden(isScrollingDown);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);
  
  return (
    <>
      <Navbar isInGallery={false} hidden={navbarHidden}/>
      <div className="all-services-all-included">
        {/* header --------------------- */}
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {/* left section ----------------------- */}
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 10,
              }}
            >
              {t('whatWeDo')}
              <div
                style={{ width: 100, height: 1, backgroundColor: "white" }}
              ></div>
            </div>
            <div className="all-services-our-services-text">{t('ourServicesUpperCase')}</div>
          </div>

          {/* right section hidden for small screen */}
          <div className="all-services-right-section">
            {t('servicesLeftDsc')}
          </div>
        </div>

        <div className="all-services-section">
          <ServicesCardFW
            icon={<ServicesIcon1 />}
            title={t("serv1")}
            description1={t("serv1desc1")}
            description2={t("serv1desc2")}
            description3={t("serv1desc3")}
            backgroundClassName="service-card-background-1"
          />
          <ServicesCardFW
            icon={<ServicesIcon2 />}
            title={t("serv2")}
            description1={t("serv2desc1")}
            description2={t("serv2desc2")}
            backgroundClassName="service-card-background-5"
          />
          <ServicesCardFW
            icon={<ServicesIcon3 />}
            title={t("serv3")}
            description1={t("serv3desc1")}
            description2={t("serv3desc2")}
            description3={t("serv3desc3")}
            backgroundClassName="service-card-background-2"
          />
          <ServicesCardFW
            icon={<ServicesIcon1 />}
            title={t("serv4")}
            description1={t("serv4desc1")}
            description2={t("serv4desc2")}
            backgroundClassName="service-card-background-3"
          />
          <ServicesCardFW
            icon={<ServicesIcon2 />}
            title={t("serv5")}
            description1={t("serv5desc1")}
            backgroundClassName="service-card-background-4"
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ServicesScreen;
