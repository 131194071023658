import React from "react";
import { SikaLogo } from "../assets/Icons";
import { useTranslation } from "react-i18next";

const Partner = () => {
  const { t } = useTranslation();

  return (
    <div className="partner-section">
      <div className="partner-content">
        <h1 className="partner-header">{t("partnerHeader")}</h1>
        <p className="partner-description">{t("partnerDescription")}</p>
        <p className="partner-moto">{t("partnerMoto")}</p>
        <a className="partner-visit-button" target="_blank" rel="noopener noreferrer" href="https://www.sika.com/">
          {t("partnerVisitButton")}
        </a>
      </div>
      <div className="partner-logo">
        <SikaLogo />
      </div>
    </div>
  );
};

export default Partner;
