import React, { useState, useRef, useEffect } from "react";
import "./styles.css";
import { BurgerIcon, LogoIcon } from "../assets/Icons";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";

const Navbar = ({ isInGallery, hidden, shadow }: { isInGallery?: boolean, hidden?: boolean, shadow?: true }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const navRef = useRef<HTMLDivElement>(null);

  const toggleMenu = (shouldOpen: any) => {
    if (window.innerWidth >= 768) return; // Ignore toggle logic on larger screens

    if (shouldOpen) {
      setIsOpen(true);
    } else {
      setIsClosing(true);
      setTimeout(() => {
        setIsOpen(false);
        setIsClosing(false);
      }, 300); // Duration of the slide-up animation
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (window.innerWidth >= 768) return; // Ignore outside clicks on larger screens

      if (navRef.current && !navRef.current.contains(event.target)) {
        if (isOpen) {
          toggleMenu(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <nav className={`navbar z-50 ${hidden ? 'navbar-hidden' : ''} ${shadow ? 'box-shadow-blur' : ''}`}>
      <div className="container mx-auto flex justify-between items-center px-0 sm:px-10">
        <div className="navbar-logo">
          <LogoIcon />
        </div>
        <div className="burger-icon" onClick={() => toggleMenu(!isOpen)}>
          <BurgerIcon />
        </div>
        <div
          ref={navRef}
          className={`navbar-menu ${isOpen ? "open" : ""} ${isClosing ? "close" : ""} font-semibold text-[#676767]`}
        >
          <ul>
            <li onClick={() => toggleMenu(false)}>
              <a href="/" className="hover:text-gray-300 whitespace-nowrap">
                {t("nav1")}
              </a>
            </li>
            <li onClick={() => toggleMenu(false)}>
              <a
                href={"/about"}
                className="hover:text-gray-300 whitespace-nowrap"
              >
                {t("nav2")}
              </a>
            </li>
            <li onClick={() => toggleMenu(false)}>
              <a
                href={"/services"}
                className="hover:text-gray-300 whitespace-nowrap"
              >
                {t("nav3")}
              </a>
            </li>
            <li onClick={() => toggleMenu(false)}>
              <a
                href={"/gallery"}
                className="hover:text-gray-300 whitespace-nowrap"
              >
                {t("nav4")}
              </a>
            </li>
            {window.innerWidth <= 768 ? <hr className="w-full bg-black" /> : ''}
            <li className="flex items-center justify-center md:justify-start md:flex-row whitespace-nowrap">
              <LanguageSwitcher toggleMenu={toggleMenu}/>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
