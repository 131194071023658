import React from "react";
import { useTranslation } from "react-i18next";
import { AboutIcon, WhyChooseUsIcon } from "../assets/Icons";
import { useNavigate } from "react-router-dom";

const About = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/about"); // Navigate to the services page or section
  };

  return (
    <>
      <div className="flex flex-col md:flex-row space-x-0 sm:space-x-16 space-y-10 sm:space-y-0 px-5 py-10 sm:px-32 sm:py-20 justify-center items-center h-fit">
        <AboutIcon />
        <div className="flex-1 flex-col">
          <h1 className="text-xl sm:text-3xl font-bold">{t("about1")}</h1>
          <p className="text-lg sm:text-xl about-description-my">
            {t("about2")} <br />
            <br />
            {t("about3")}
            <span className="font-bold">{t("about4")}</span>
          </p>
          <button onClick={handleNavigate} className="about-button">
            {t("moreInfo")}
          </button>
        </div>
      </div>

      {/* ---------- where we operate? ---------- */}
      <div className="where-we-operate-container">
        <div className="about-yellow-line"></div>
        <div className="flex flex-col space-y-5 text-white">
          <h1 className="text-xl sm:text-3xl font-bold">{t("whereWeOperate")}</h1>
          <p>
            {t("whereWeOperateDescription")}
          </p>
        </div>
      </div>

      {/* ---------- why choose us? ---------- */}
      <div className="why-content-container">
        <WhyChooseUsIcon />
        <div className="why-text-container">
          <h1 className="why-title">{t("whyChooseUs")}</h1>
          <ul className="why-benefits-list">
            <li>
              <strong>{t("expertiseAndExperience")}</strong> {t("expertiseAndExperienceDescription")}
            </li>
            <li>
              <strong>{t("qualityAndDurability")}</strong> {t("qualityAndDurabilityDescription")}
            </li>
            <li>
              <strong>{t("aestheticAppeal")}</strong> {t("aestheticAppealDescription")}
            </li>
            <li>
              <strong>{t("comprehensiveService")}</strong> {t("comprehensiveServiceDescription")}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default About;
