import React from "react";
import { ServicesCard } from "./ServicesCard";
import {
  ServicesIcon1,
  ServicesIcon2,
  ServicesIcon3,
} from "../assets/Icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/services"); // Navigate to the services page or section
  };
  return (
    <div className="services-all-included">
      {/* header --------------------- */}
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {/* left section ----------------------- */}
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
            }}
          >
            {t("whatWeDo")}
            <div
              style={{ width: 100, height: 1, backgroundColor: "white" }}
            ></div>
          </div>
          <div className="our-services-text">{t("ourServicesUpperCase")}</div>
        </div>

        {/* right section hidden for small screen!!!!!!!!!!! */}
        <div className="right-section">{t("servicesLeftDsc")}</div>
      </div>

      <div className="services-section">
        <ServicesCard
          icon={<ServicesIcon1 />}
          title={t("serv1")}
          description={t("serv1desc1")}
          backgroundClassName="service-card-background-1"
        />
        <ServicesCard
          icon={<ServicesIcon2 />}
          title={t("serv2")}
          description={t("serv2desc1")}
          backgroundClassName="service-card-background-5"
        />
        <ServicesCard
          icon={<ServicesIcon3 />}
          title={t("serv3")}
          description={t("serv3desc1")}
          backgroundClassName="service-card-background-2"
        />
      </div>

      <div style={{ display: "flex", flex: 1, justifyContent: "end" }}>
        <button className="services-button" onClick={handleNavigate}>
          {t("moreInfo")}
        </button>
      </div>
    </div>
  );
};

export default Services;
