import React from 'react';
import HeroSection from '../components/HeroSection';
import Experience from '../components/Experience';
// import Services from '../components/Services';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
// import Partner from '../components/Partner';
import Services from '../components/Services';
import Partner from '../components/Partner';
import Navbar from '../components/Navbar';

const Landing = () => {
  const [prevScrollPos, setPrevScrollPos] = React.useState(window.pageYOffset);
  const [navbarHidden, setNavbarHidden] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingDown = prevScrollPos < currentScrollPos && currentScrollPos > 50;

      setNavbarHidden(isScrollingDown);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  return (
    <div>
      <Navbar hidden={navbarHidden} />
      <HeroSection id="home" />
      <Experience />
      <Services />
      <Partner />
      <Contact id="contact" />
      <Footer />
    </div>
  );
};

export default Landing;
