import React from "react";
import Card from "./Card";
import About from "./About";
import {
  IndustryExperienceIcon,
  MeterIcon,
  ProjectsCompletedIcon,
  StatisfiedIcon,
} from "../assets/Icons";
import { useTranslation } from "react-i18next";

const ExperienceSection = () => {
  const { t } = useTranslation();
  return (
    <div className="relative">
      <div className="flex flex-col md:flex-row p-20 justify-center gap-20 h-auto items-center">
        <Card
          title="13"
          description={t("ex1")}
          icon={<ProjectsCompletedIcon />}
        />
        <Card title="10" span="K" description={t("ex2")} icon={<MeterIcon />} />
        <Card
          title="98"
          span="%"
          description={t("ex3")}
          icon={<StatisfiedIcon />}
        />
        <Card
          title="3"
          span={t('years')}
          description={t("ex4")}
          icon={<IndustryExperienceIcon />}
        />
      </div>
      <About />
      <div className="absolute -bottom-[30px] h-[30px] left-0 w-full bg-white rounded-br-3xl rounded-bl-3xl z-50" />
    </div>
  );
};

export default ExperienceSection;
