import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Landing from './screens/Landing';
import Gallery from './components/Gallery';
import AboutScreen from './screens/AboutScreen';
import ServicesScreen from './screens/ServicesScreen';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/about" element={<AboutScreen />} />
        <Route path="/services" element={<ServicesScreen />} />
      </Routes>
    </Router>
  )
}

export default App;
