import React from 'react';

type CardProps = {
  icon: JSX.Element;
  title: string;
  description: string;
  span?: string;
};

const Card = ({ icon, title, description, span }: CardProps): JSX.Element => {
  return (
      <div className="relative bg-gradient-to-b from-yellow-400 to-white rounded-lg shadow-md p-6 text-center w-48 h-48">
        <div className="text-6xl font-bold text-black">{title}<span className='text-2xl'>{span}</span></div>
        <div className="mt-2 text-red-500 text-sm font-medium">{description}</div>
        <div className="absolute -bottom-10 left-1/2 transform -translate-x-1/2 bg-white w-20 h-20 rounded-full shadow-md flex items-center justify-center">
          {icon}
        </div>
      </div>
  );
};

export default Card;
