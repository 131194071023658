import React from "react";
import {
  FacebookIcon,
  InstagramIcon,
  MailIcon,
  PhoneIconRounded,
  WhatsappIcon,
} from "../assets/Icons";

const Footer = () => {
  return (
    <footer
      style={{ backgroundColor: "#8D8D8D" }}
      className=" text-white flex justify-between items-center h-fit sm:h-20 px-10 flex-col sm:flex-row py-10 sm:py-0 space-y-5 sm:space-y-0"
    >
      <div>© 2024 Copyright - NIKI EPOXY</div>
      <div className="flex space-x-4">
        <a
          href="https://www.facebook.com/profile.php?id=61563069564283&mibextid=LQQJ4d"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-gray-400"
        >
          <FacebookIcon />
        </a>
        <a
          href="https://www.instagram.com/niki_epoxy"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-gray-400"
        >
          <InstagramIcon />
        </a>
        <a
          href="https://wa.me/+38970454578"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-gray-400"
        >
          <WhatsappIcon />
        </a>
        <a href="tel:+38970454578" className="hover:text-gray-400">
          <PhoneIconRounded />
        </a>
        <a href="mailto:nikiepoxy.mk@gmail.com" className="hover:text-gray-400">
          <MailIcon />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
