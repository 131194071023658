import React, { useState, useEffect } from "react";
import { RightArrowIcon } from "../assets/Icons";
import { useNavigate } from "react-router-dom";

interface ServicesCardProps {
  icon: any;
  title: string;
  description: string;
  backgroundClassName: string;
}

export const ServicesCard: React.FC<ServicesCardProps> = ({
  icon,
  title,
  description,
  backgroundClassName,
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate(); // Initialize useNavigate hook

  // Effect to update screen width on window resize
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to truncate the description
  const truncateDescription = (text: string, maxLength: number) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  const isWidthInRange = screenWidth >= 1000 && screenWidth <= 1300;

  const handleNavigate = () => {
    navigate("/services"); // Navigate to the services page or section
  };

  return (
    <div className={`service-card ${backgroundClassName}`}>
      <div className="card-content">
        <div className="service-card-icon">{icon}</div>
        <h3 className="service-card-title">{title}</h3>
      </div>
      <div className="service-card-description">
        <p>
          {isWidthInRange ? truncateDescription(description, 190) : description}
        </p>
        <button className="service-card-icon-bottom" onClick={handleNavigate}>
          <RightArrowIcon />
        </button>
      </div>
    </div>
  );
};
