import React from "react";
import Navbar from "./Navbar";
import "../styles/Gallery.css";
import Footer from "./Footer";
import CustomCarousel from "./CustomCarousel";
import { useTranslation } from "react-i18next";

const Gallery: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="gallery-container">
        <Navbar isInGallery={true} />

        <div className="gallery-content">
          <div className="gallery-text">
            <h1 className="gallery-title">{t("galleryTitle")}</h1>
            <p className="gallery-description">
              {t("galleryDescription")}
            </p>
          </div>
          <div className="gallery-carousel">
            <CustomCarousel />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
