import vid1 from "../assets/gallery/vid1.mp4";
import vid2 from "../assets/gallery/vid2.mp4";
import vid3 from "../assets/gallery/vid3.mp4";
import vid4 from "../assets/gallery/vid4.mp4";
import vid5 from "../assets/gallery/vid5.mp4";
import vid6 from "../assets/gallery/vid6.mp4";
import vid7 from "../assets/gallery/vid7.mp4";
import vid8 from "../assets/gallery/vid8.mp4";
import vid9 from "../assets/gallery/vid9.mp4";

import thumb1 from "../assets/gallery/thumb1.jpg";
import thumb2 from "../assets/gallery/thumb2.jpg";
import thumb3 from "../assets/gallery/thumb3.jpg";
import thumb4 from "../assets/gallery/thumb4.jpg";
import thumb5 from "../assets/gallery/thumb5.jpg";
import thumb6 from "../assets/gallery/thumb6.jpg";
import thumb7 from "../assets/gallery/thumb7.jpg";
import thumb8 from "../assets/gallery/thumb8.jpg";
import thumb9 from "../assets/gallery/thumb9.jpg";

import img1 from "../assets/gallery/1.jpg";
import img2 from "../assets/gallery/2.jpg";
import img3 from "../assets/gallery/3.jpg";
import img4 from "../assets/gallery/4.jpg";
import img5 from "../assets/gallery/5.jpg";
import img6 from "../assets/gallery/6.jpg";
import img7 from "../assets/gallery/7.jpg";
import img8 from "../assets/gallery/8.jpg";
import img9 from "../assets/gallery/9.jpg";
import img10 from "../assets/gallery/10.jpg";
import img11 from "../assets/gallery/11.jpg";
import img12 from "../assets/gallery/12.jpg";
import img13 from "../assets/gallery/13.jpg";
import img14 from "../assets/gallery/14.jpg";
import img15 from "../assets/gallery/15.jpg";
import img16 from "../assets/gallery/16.jpg";
import img17 from "../assets/gallery/17.jpg";
import img18 from "../assets/gallery/18.jpg";
import img19 from "../assets/gallery/19.jpg";
import img20 from "../assets/gallery/20.jpg";
import img21 from "../assets/gallery/21.jpg";
import img22 from "../assets/gallery/22.jpg";
import img23 from "../assets/gallery/23.jpg";
import img24 from "../assets/gallery/24.jpg";
import img25 from "../assets/gallery/25.jpg";
import img26 from "../assets/gallery/26.jpg";
import img27 from "../assets/1.png";
import img28 from "../assets/2.png";
import img29 from "../assets/3.png";
import img30 from "../assets/4.png";
import img31 from "../assets/microcemento.png";

const mediaData = [
  // Video data
  { id: 1, type: "video", url: vid1, thumb: thumb1, alt: "Description of 1" },
  { id: 2, type: "video", url: vid2, thumb: thumb2, alt: "Description of 2" },
  { id: 3, type: "video", url: vid3, thumb: thumb3, alt: "Description of 3" },
  { id: 4, type: "video", url: vid4, thumb: thumb4, alt: "Description of 4" },
  { id: 5, type: "video", url: vid5, thumb: thumb5, alt: "Description of 5" },
  { id: 6, type: "video", url: vid6, thumb: thumb6, alt: "Description of 6" },
  { id: 7, type: "video", url: vid7, thumb: thumb7, alt: "Description of 7" },
  { id: 8, type: "video", url: vid8, thumb: thumb8, alt: "Description of 8" },
  { id: 9, type: "video", url: vid9, thumb: thumb9, alt: "Description of 9" },

  // Image data
  { id: 15, type: "image", url: img1, alt: "Description of 1" },
  { id: 16, type: "image", url: img2, alt: "Description of 2" },
  { id: 17, type: "image", url: img3, alt: "Description of 3" },
  { id: 18, type: "image", url: img4, alt: "Description of 4" },
  { id: 19, type: "image", url: img5, alt: "Description of 5" },
  { id: 20, type: "image", url: img6, alt: "Description of 6" },
  { id: 21, type: "image", url: img7, alt: "Description of 7" },
  { id: 22, type: "image", url: img8, alt: "Description of 8" },
  { id: 23, type: "image", url: img9, alt: "Description of 9" },
  { id: 24, type: "image", url: img10, alt: "Description of 10" },
  { id: 25, type: "image", url: img11, alt: "Description of 11" },
  { id: 26, type: "image", url: img12, alt: "Description of 12" },
  { id: 27, type: "image", url: img13, alt: "Description of 13" },
  { id: 28, type: "image", url: img14, alt: "Description of 14" },
  { id: 29, type: "image", url: img15, alt: "Description of 15" },
  { id: 30, type: "image", url: img16, alt: "Description of 16" },
  { id: 31, type: "image", url: img17, alt: "Description of 17" },
  { id: 32, type: "image", url: img18, alt: "Description of 18" },
  { id: 33, type: "image", url: img19, alt: "Description of 19" },
  { id: 34, type: "image", url: img20, alt: "Description of 20" },
  { id: 35, type: "image", url: img21, alt: "Description of 21" },
  { id: 36, type: "image", url: img22, alt: "Description of 22" },
  { id: 37, type: "image", url: img23, alt: "Description of 23" },
  { id: 38, type: "image", url: img24, alt: "Description of 24" },
  { id: 39, type: "image", url: img25, alt: "Description of 25" },
  { id: 40, type: "image", url: img26, alt: "Description of 26" },
  { id: 41, type: "image", url: img27, alt: "Description of 27" },
  { id: 42, type: "image", url: img28, alt: "Description of 28" },
  { id: 43, type: "image", url: img29, alt: "Description of 29" },
  { id: 44, type: "image", url: img30, alt: "Description of 30" },
  { id: 45, type: "image", url: img31, alt: "Description of 31" },
];

export default mediaData;
