import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import svg from "../assets/LocationIcon.svg";
import L from "leaflet";
import { ContactBg, EmailIcon, LocationIcon, PhoneIcon } from "../assets/Icons";
import { useTranslation } from "react-i18next";

const Contact = ({ id }: { id: any }) => {
  const { t } = useTranslation();
  const customIcon = new L.Icon({
    iconUrl: svg,
    iconSize: [38, 38], // Size of the icon
    iconAnchor: [19, 38], // Anchor point of the icon
    popupAnchor: [0, -38], // Popup anchor point relative to the icon
  });

  return (
    <div id={id} className="relative flex justify-center items-center h-svh ">
      <div className="absolute top-0 w-full">
        <ContactBg />
      </div>
      <div className="absolute top-0 px-5 sm:px-20 py-5 sm:py-36 flex flex-col md:flex-row w-full justify-center gap-10 h-svh">
        <div
          style={{ backgroundColor: "rgba(230, 230, 230, 0.8)" }}
          className="text-black flex-1 md:max-w-[40%] p-6 sm:p-14 flex flex-col justify-between shadow-md rounded-xl max-h-[370px]"
        >
          <h2 className="text-2xl md:text-4xl font-bold">{t("contact1")}</h2>
          <div className="flex flex-col gap-5 sm:gap-10">
            <div className="flex gap-2 items-center text-[12px] sm:text-[18px] font-semibold">
              <EmailIcon />
              <p>nikiepoxy.mk@gmail.com</p>
            </div>
            <div className="flex gap-2 items-center text-[12px] sm:text-[18px] font-semibold">
              <PhoneIcon />
              <p>+389 70 454 578</p>
            </div>
            <div className="flex gap-2 items-center text-[12px] sm:text-[18px] font-semibold">
              <LocationIcon />
              <p>Dzepciste, ul 101, Nr: BB</p>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-5 font-md md:font-xl">
            <a href="tel:+38970454578" className="bg-[#FFC510] font-bold py-2 w-full rounded transform transition-transform duration-300 hover:scale-105 flex justify-center">
              {t("contact2")}
            </a>
            <a href="mailto:nikiepoxy.mk@gmail.com" className="bg-transparent text-[#FFC510] font-bold py-2 w-full rounded border border-1 border-[#FFC510] transform transition-transform duration-300 hover:scale-105 flex justify-center">
              {t("contact3")}
            </a>
          </div>
        </div>
        <div className="flex-1 rounded-xl shadow-md md:max-w-[40%] max-h-[370px]">
          <MapContainer
            className="w-full h-full rounded-xl"
            center={[42.03034370856042, 21.000014169948813]}
            zoom={15}
          >
            <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker
              position={[42.03034370856042, 21.000014169948813]}
              icon={customIcon}
            >
              <Popup>Džepčište, North Macedonia.</Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export default Contact;
