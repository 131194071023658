import React from "react";

interface ServicesCardFWProps {
  icon: any;
  title: string;
  description1: string;
  description2?: string;
  description3?: string;
  backgroundClassName: string;
}

export const ServicesCardFW: React.FC<ServicesCardFWProps> = ({
  icon,
  title,
  description1,
  description2,
  description3,
  backgroundClassName
}) => {
  return (
    <div className={`all-services-card ${backgroundClassName}`}>
      <div className="all-services-card-content">
        <div className="all-services-card-icon">{icon}</div>
        <h3 className="all-services-card-title">{title}</h3>
      </div>
      <div className="all-services-card-description">
        <p>{description1}</p>
        {description2 ? <p>{description2}</p> : ""}
        {description3 ? <p>{description3}</p> : ""}
      </div>
    </div>
  );
};
