import React, { useState, useRef } from "react";
import "../styles/CustomCarousel.css";
import mediaData from "./mediaData";

const CustomCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const thumbnailsRef = useRef<HTMLDivElement>(null);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex === 0 ? mediaData.length - 1 : prevIndex - 1;
      handleScroll(newIndex);
      return newIndex;
    });
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex === mediaData.length - 1 ? 0 : prevIndex + 1;
      handleScroll(newIndex);
      return newIndex;
    });
  };

  const selectSlide = (index: number) => {
    setCurrentIndex(index);
    handleScroll(index);
  };

  const handleScroll = (index: number) => {
    const thumbnailContainer = thumbnailsRef.current;
    const thumbnailWidth = 60 + 10; // Width of thumbnail + margin
    const containerWidth = thumbnailContainer!.clientWidth;
    const scrollPosition =
      thumbnailWidth * index - (containerWidth - thumbnailWidth) / 2;

    thumbnailContainer!.scrollLeft = scrollPosition;
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const renderMedia = () => {
    const media = mediaData[currentIndex];
    if (media.type === "image") {
      return (
        <div
          className="carousel-main-image"
          style={{ backgroundImage: `url(${media.url})` }}
        >
          <button className="carousel-control prev" onClick={prevSlide}>
            &lt;
          </button>
          <button className="carousel-control next" onClick={nextSlide}>
            &gt;
          </button>
          <button className="expand-icon" onClick={openModal}>
            &#x26F6;
          </button>
        </div>
      );
    } else if (media.type === "video") {
      return (
        <div className="carousel-main-image">
          <video key={media.url} controls poster={media.thumb}>
            <source src={media.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <button className="carousel-control prev" onClick={prevSlide}>
            &lt;
          </button>
          <button className="carousel-control next" onClick={nextSlide}>
            &gt;
          </button>
        </div>
      );
    }
  };

  return (
    <div className="carousel-container">
      {renderMedia()}

      {/* Thumbnails */}
      <div className="carousel-thumbnails" ref={thumbnailsRef}>
        {mediaData.map((media, index) => (
          <div
            key={index}
            className={`thumbnail ${currentIndex === index ? "active" : ""}`}
            style={{
              backgroundImage:
                media.type === "image"
                  ? `url(${media.url})`
                  : `url(${media.thumb})`,
              backgroundColor: "transparent",
            }}
            onClick={() => selectSlide(index)}
          />
        ))}
      </div>

      {/* Modal for enlarged image or video */}
      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <span className="close-modal" onClick={closeModal}>
            &times;
          </span>
          {mediaData[currentIndex].type === "image" ? (
            <img
              className="modal-content"
              src={mediaData[currentIndex].url}
              alt="Enlarged"
            />
          ) : (
            <video controls className="modal-content">
              <source src={mediaData[currentIndex].url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomCarousel;
